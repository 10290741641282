import {Form, Input, Modal, Select, Row, Col, Switch} from "antd";
import {LockOutlined} from '@ant-design/icons'
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../utils/useStores";
import * as _ from "lodash";

export const UserForm = observer(({visible, onSubmit, onCancel, confirmLoading, initialData, role}) => {
	const [form] = Form.useForm();
	const [showPartnerSelection, setShowPartnerSelection] = useState(false);
	const store = useStore();

	useEffect(() => {
		init();
		setShowPartnerSelection(initialData.role_id === 'a0c395ff-a7b6-4668-95ad-63bbcb31485f');
	}, []);

	async function init() {
	}
	
	function setFieldsValue(){
		console.log(initialData?.role?.id)
		form.setFieldsValue({
			role : initialData?.role?.id
		})
	}
	

	function onChange(checked) {
		console.log(`switch to ${checked}`);
	  }

	return <Modal
		maskClosable={false}
		visible={visible}
		width={'448px'}
		closable={false}
		bodyStyle={{background: '#f7fafc'}}
		title="Module Training"
		okText="Save"
		cancelText="Cancel"
		onCancel={() => {
			form.setFieldsValue({});
			onCancel();
		}}
		confirmLoading={confirmLoading}
		destroyOnClose={true}
		onOk={() => {
			form
				.validateFields()
				.then(values => {
					onSubmit(values);
					form.setFieldsValue({});
				})
				.catch(info => {
					console.log('Validate Failed:', info);
				});
		}}
	>

		<Form
			form={form}
			layout="vertical"
			className={'custom-form'}
			name="form_in_modal"
			initialValues={initialData}

			onValuesChange={(values) => {
				console.log({values}, 'values -> ')
				if(values.role_id) {
					if(values.role_id === 'a0c395ff-a7b6-4668-95ad-63bbcb31485f') {
						setShowPartnerSelection(true);
					} else {
						setShowPartnerSelection(false);
					}
				}
			}}
		>
			<Form.Item
				name='id' hidden={true}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name='name'
				label="Name"
				rules={[
					{
						required: true,
						message: 'Please input name!',
					},
				]}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				name="email"
				label="Email"
				rules={[
					{
						required: true,
						message: 'Please input email!',
					},
				]}
			>
				<Input/>
			</Form.Item>
			<Form.Item
				name="password"
				label="Password"
				rules={[
					{
						required: initialData?.id ? false :true,
						message: 'Please input password!',
					},
				]}
			>
				<Input.Password
					prefix={<LockOutlined className="site-form-item-icon"/>}
					type="password"
					placeholder="Password"
				/>
			</Form.Item>
			{setFieldsValue()}
			<Form.Item
				name="role"
				label="Role"
				rules={[
					{
						required: true,
						message: 'Please input role!',
					},
				]}
			>
				<Select placeholder="Select Role">
						{role.map(d => <Select.Option key={d.id} value={d.id}>{d.role}</Select.Option>)}
				</Select>
			</Form.Item>
			

		</Form>
	</Modal>
});
