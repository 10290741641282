import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  PageHeader,
  Table,
  Input,
  Select,
  Space,
  Typography,
  Modal,
  Form,
  DatePicker,
  Breadcrumb,
  Empty,
  message, Pagination, Switch
} from "antd";
import { FilterOutlined, PlusOutlined,SearchOutlined,EditOutlined, DeleteOutlined,DownloadOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import {useStore} from "../../utils/useStores";
import { WilayahForm } from "./WilayahForm";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import * as _ from "lodash";
import moment from "moment";
import Highlighter from "react-highlight-words";
import {Link, useHistory} from "react-router-dom";
import exportFromJSON from 'export-from-json'
import {useQuery, gql, useMutation} from "@apollo/client";

const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;
var QRCode = require('qrcode.react');
const saveSvgAsPng = require('save-svg-as-png')

let selectedRole;
export const WilayahList = observer((props) => {
  const { RangePicker } = DatePicker;
  const store = useStore();
  let history = useHistory();
  const [form] = Form.useForm();
  const [showModuleFormModal, setShowModuleFormModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialData, setInitialData] = useState({});
  const[state,setState] = useState({
    username : '',
    fullName : '',
    role : '',
    partner : '',
    last_login : ''
  })

  useEffect(() => {
    fetchDataWilayah()

  }, []);


  async function fetchDataWilayah() {
    console.log('Get ALL Membership Datas')
    store.wilayah.getAll()
    console.log({roleData: store.wilayah.data}, "Anggota Ikai Data");
  }

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: 2,
      render: (text, records, index) => {
        return {
          children: text,
          props: {}
        }
      }
    },

    {
      title: "Name",
      key: 3,
      dataIndex: "name",
      render: (text) => {
        return {
          children: text,
          props: {}
        }
      },
    },
    {
      title: "Attribute",
      key: "code",
      dataIndex: "code",
      render: (text) => {
        return {
          children: text,
          props: {}
        }
      },
    },
    {
      title: "Action",
      fixed : store.ui.mediaQuery.isMobile ? '' :'right',
      align :'center',
      key:9,
      render: (text, record) => {
        return (
            <div>

              <Button
                  onClick={() => {
                    setInitialData(record);
                    setShowModuleFormModal(true);
                  }}
              >
                <EditOutlined />
              </Button>
              <Button
                  onClick={() => {
                    Modal.confirm({
                      title: "Anda ingin menghapus data " + `{${record.name}} ?`,
                      icon: <ExclamationCircleOutlined />,
                      async onOk() {
                        await store.wilayah.delete(record.id)
                        fetchDataWilayah()
                      },
                      onCancel() {},
                    });
                  }}
                  style={{
                    margin: 8,
                  }}
              >
                <DeleteOutlined />
              </Button>
            </div>
        );
      },
    },
  ]

  function onOkFilter(){
  }

  function resetFilter(){
    form.validateFields().then((values) => {
      form.resetFields();
    });
    setState({
      role : '',
      partner : '',
      last_login : ''
    });
    fetchDataWilayah()
  }

  async function onSubmit(values) {}
  async function onSearch(val) {
    store.wilayah.query['search'] = val
  }



  return (
      <div>
        <Breadcrumb style={{marginBottom: 20}}>
          <Breadcrumb.Item>
            <Link to={'/app'}>Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{color:"#ed1f24"}}>Masterdata Wilayah</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        {/* <Spin spinning={store.user.isLoading}> */}
        <Card bordered={false} className={"shadow"} bodyStyle={{ padding: 0 }}>
          {/* {modalFilter()} */}
          <PageHeader
              className={"card-page-header"}
              //   title={
              //   <Button
              //     icon={<FilterOutlined/>}
              //     size={'small'}
              //     style={{margin: 3}}
              //     onClick={() => {
              //       setFilterDialog(true)
              //     } }>
              //     Filter
              // </Button>
              // }
              //   onBack={() => window.history.back()}
              title="Masterdata Wilayah"
              extra={[

                // <Search allowClear={true} placeholder="Pencarian Anggota" onSearch={onSearch} style={{ fixed: 'left',width: 200 }} />,
                <Button
                    key="1"
                    type="primary"
                    onClick={() => {
                      setInitialData({});
                      setShowModuleFormModal(true);
                    }}
                >
                  <PlusOutlined /> Tambah
                </Button>,
                <Button
                    onClick={() => {
                      const data = _.map(store.wilayah.data, val=>{
                        return {
                          id: val.id,
                          name: val.name,
                          code: val.code
                        }
                      })
                      const fileName = 'list_wilayah_ikai';
                      const exportType =  exportFromJSON.types.xls

                      exportFromJSON({ data, fileName, exportType })
                    }
                    }
                >
                  <DownloadOutlined />
                </Button>
              ]}
          />
          {!store.wilayah.isLoading && store.wilayah.data ? store.wilayah.data.length > 0 ? (
              <><Table
                  // scroll={{x: 1300,y: 'calc(100vh - 4em)'}}
                  hasEmpty
                  size={"small"}
                  // scroll={{ x: 1300, y: 'calc(100vh - 4em)'  }}
                  dataSource={!store.wilayah.isLoading && store.wilayah.data ? store.wilayah.data || [] : []}
                  loading={store.wilayah.data.length === 0}
                  columns={tableColumns}
              />
              </>
          ) : (
              <div
                  style={{
                    minHeight: "calc(100vh - 450px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
              >
                <Empty />
              </div>
          ):false}
        </Card>

        {showModuleFormModal && (
            <WilayahForm
                visible={showModuleFormModal}
                onSubmit={async (data) => {
                  onSubmit(data)
                  setConfirmLoading(true);
                  console.log(data)
                  let result;
                  try {

                    const body = {
                      "name": data.name,
                      "code": data.code,
                      "category": 'masterdata',
                      "type": 'wilayah',
                    }

                    if(data.id) {
                      result = await store.wilayah.edit(data.id, body)
                    } else {
                      result = await store.wilayah.add(body)
                    }

                    console.log(result)

                    if(result.body.status == 200) {
                      message.success('Data Tersimpan!')
                    } else {
                      message.error('Data Gagal Tersimpan')
                    }
                    fetchDataWilayah()
                  } catch (e) {
                    message.error('ERROR: ' + e.message)
                  }

                  setConfirmLoading(false);
                  setShowModuleFormModal(false);
                }}
                onCancel={() => {
                  setShowModuleFormModal(false);
                }}
                confirmLoading={confirmLoading}
                initialData={initialData}
            />
        )}
        {/* </Spin> */}
      </div>
  );
});
