import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { StoreProvider } from "./utils/useStores";
import { MainRoutes } from "./routes";
import "./custom.less";
import "./Style.css";
import ParticlesBg from "particles-bg";
import { ClearCacheProvider } from 'react-clear-cache';
import {ClearCache} from './utils/clearCache'

import {graphqlClient} from "./utils/graphql";
import {ApolloProvider} from "@apollo/client";

export const Main = () => {
  return (
      <ClearCacheProvider duration={5000}>
          <StoreProvider>
            <Router>
                <ClearCache />
              <MainRoutes />
              <ParticlesBg color={"#ababab"} num={20} type={"cobweb"} bg={true} />
            </Router>
          </StoreProvider>
      </ClearCacheProvider>
  );
};
