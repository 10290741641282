import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  PageHeader,
  Table,
  Input,
  Select,
  Space,
  Typography,
  Modal,
  Form,
  DatePicker,
  Breadcrumb,
  Empty,
  message, Pagination, Switch
} from "antd";
import { FilterOutlined, PlusOutlined,SearchOutlined,EditOutlined, DeleteOutlined,DownloadOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import {useStore} from "../../utils/useStores";
import { MembershipForm } from "./MembershipForm";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import * as _ from "lodash";
import moment from "moment";
import Highlighter from "react-highlight-words";
import {Link, useHistory} from "react-router-dom";
import exportFromJSON from 'export-from-json'
import QRCodeCanvas, { QRCodeSVG } from 'qrcode.react';
import axios from 'axios';

const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;
var fileDownload = require('js-file-download');

const saveSvgAsPng = require('save-svg-as-png')

let selectedRole;
export const MembershipList = observer((props) => {
  const { RangePicker } = DatePicker;
  const store = useStore();
  let history = useHistory();
  const [form] = Form.useForm();
  const [showModuleFormModal, setShowModuleFormModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [filterDialog, setFilterDialog] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const[state,setState] = useState({
    username : '',
    fullName : '',
    role : '',
    partner : '',
    last_login : ''
  })

  useEffect(() => {
    fetchData();
    fetchDataWilayah()

  }, []);

  async function fetchData() {
    console.log('Get ALL Membership Datas')

    store.membership.getAll()
    console.log({roleData: store.membership.data}, "Anggota Ikai Data");
  }

  async function fetchDataWilayah() {
    console.log('Get ALL Membership Datas')
    store.wilayah.getAll()
    console.log({roleData: store.membership.data}, "Anggota Ikai Data");
  }

  const level = [
      {
          name : "Level 1",
          value: "K1"
      },
      {
          name : "Level 2",
          value: "K2"
      },
      {
          name : "Level 3",
          value : "K3"
      },
      {
          name : "Other",
          value: "0"
      }
  ]

  const status = [
    {
      id: 'ea0fbf54-4461-11eb-b378-0242ac130002',
      key: 1,
      value: 'Aktif'
    },
    {
      id: 'ea0fc18e-4461-11eb-b378-0242ac130002',
      key: 2,
      value: 'Tidak Aktif'
    }
  ]



  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            const searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()):'',
    onFilterDropdownVisibleChange: (visible) => {
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleOk = () => {

    saveSvgAsPng.saveSvgAsPng(document.getElementById("qrcode-container"),
        "qrabsen.png",
        {
          scale: 15,
        }
    );
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDownload = (url, filename) => {
      axios.get(url, {
          responseType: 'blob',
      })
          .then((res) => {
              fileDownload(res.data, filename)
          })
  }

  const tableColumns = [
        {
            title: "No",
            dataIndex: "no",
            key: 0,
            width: 35,
            fixed: 'left',
            render: (text, row, index) => {
                const p = (store.membership.query.page ?? 1)-1;
                const l = store.membership.query.pageSize ?? 0;
                return index+1 + (p*l);
            },
        },
        {
            title: "NPA",
            colSpan: 2,
            key:0,
            dataIndex: "npa",
            width: 110,
            fixed: 'left',
            render: (text, row, index) => {
                const obj = {
                    children: <a onClick={
                        () => {
                            handleDownload('https://anggota.ikai.or.id/api/v1/files/qrcode?text=' + 'https://anggota.ikai.or.id/?npa='+text, text.replace(/[\W_]+/g,"_")+"-"+row.fullName.replace(/[\W_]+/g,"_")+'.png')
                        }} href={'#'}>
                        <QRCodeCanvas value={'https://anggota.ikai.or.id/?npa='+text} size={100} />
                    </a>,
                    props: {colSpan: 1},
                };
                return obj
            },
        },
      {
        colSpan: 0,
        key:0,
        title: "NPA",
        dataIndex: "npa",
        width: 100,
        fixed: 'left',
        render: (text) => {
            return {
                children: text,
                props: {}
            }
        }
      },
      {
        title: "Fullname",
        colSpan: 2,
          key: 10,
          dataIndex: "image_url",
          width: 110,
          fixed: 'left',
          render: (value, row, index) => {
              const obj = {
                  children: <img src={value} style={{width: '100px', height: '100px'}}/>,
                  props: {colSpan: 1},
              };
              return obj
          }
      },
      {
        key: 1,
        colSpan: 0,
          title: "fullName",
          dataIndex: "fullName",
          width: 100,
          fixed: 'left',
          render: (text) => {
              return {
                  children: text,
                  props: {}
              }
          }
      },
      {
        title: "Title",
        dataIndex: "title",
        key: 2,
          render: (text) => {
              return {
                  children: text,
                  props: {}
              }
          }
      },

      {
        title: "Keanggotaan",
        key: 3,
        dataIndex: "membershipType",
          render: (text) => {
              return {
                  children: text,
                  props: {}
              }
          },
        width: '100',
      },
      {
        title: "Email",
        key: "email",
          dataIndex: "email",
          render: (text) => {
              return {
                  children: text,
                  props: {}
              }
          },
      },
      {
        title: "Phone",
        key: 4,
      dataIndex: "phone",
          render: (text) => {
              return {
                  children: text,
                  props: {}
              }
          },
  },
  {
    title: "Izin Praktik Konselor (IKAI)",
        key: 5,
      dataIndex: "practicePermitNo",
      render: (text) => {
          return {
              children: text,
              props: {}
          }
      },
  },
  {
    title: "Pengalaman Konseling",
        key: 6,
      dataIndex: "experienceYear",
      render: (text) => {
          return {
              children: text,
              props: {}
          }
      },
  },
  {
    title: "Nomor Serkom",
        key: 7,
      dataIndex: "serkomNo",
      render: (text) => {
          return {
              children: text,
              props: {}
          }
      },
  },
  {
    title: "Masa Berlaku Serkom",
        key: 8,
      dataIndex: "serkomEndDate",
      render: (text) => {
        return  <>{(text)?moment(text).format('YYYY-MM-DD'):'-'}</>
      },
    // width: '15%',
  },
  {
    title: "Status",
        fixed : store.ui.mediaQuery.isMobile ? '' :'right',
      align :'center',
      dataIndex: 'status',
      key:9,
      render: (text, record) => {

    return  <Switch size="small" checked={text =='active'? true: false} onChange={(val)=>{

      try{
        store.membership.edit(record.id, {
          'status': val ? 'active' : 'inactive'
        })

        record.status = val ? 'active' : 'inactive'

      } catch (e) {
      }


    }} />
  }
  },
  {
    title: "Action",
        fixed : store.ui.mediaQuery.isMobile ? '' :'right',
      align :'center',
      key:9,
      render: (text, record) => {
    return (
        <div>

          <Button
              onClick={() => {
                // const data = JSON.parse(JSON.stringify(record));
                //   delete data.password;
                record.serkomEndDate = record.serkomEndDate?moment(record.serkomEndDate): undefined;
                record.experienceYearStart = record.experienceYearStart?moment(record.experienceYearStart): undefined;
                setInitialData(record);
                setShowModuleFormModal(true);
              }}
          >
            <EditOutlined />
          </Button>
          <Button
              onClick={() => {
                Modal.confirm({
                  title: "Anda ingin menghapus data " + `{npa:${record.npa}} ?`,
                  icon: <ExclamationCircleOutlined />,
                  async onOk() {
                    await store.membership.delete(record.id)
                    fetchData()
                  },
                  onCancel() {},
                });
              }}
              style={{
                margin: 8,
              }}
          >
            <DeleteOutlined />
          </Button>
        </div>
    );
  },
  },
]

  function onOkFilter(){
  }

  function resetFilter(){
    form.validateFields().then((values) => {
      form.resetFields();
    });
    setState({
      role : '',
      partner : '',
      last_login : ''
    });
    fetchData()
    fetchDataWilayah()
    setFilterDialog(false);
  }

  async function onSubmit(values) {}
  async function onSearch(val) {
    store.membership.query['search'] = val
    fetchData()
  }

  return (
    <div>
      <Breadcrumb style={{marginBottom: 20}}>
        <Breadcrumb.Item>
            <Link to={'/app'}>Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span style={{color:"#ed1f24"}}>Anggota IKAI</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      {/* <Spin spinning={store.user.isLoading}> */}
      <Card bordered={false} className={"shadow"} bodyStyle={{ padding: 0 }}>
      {/* {modalFilter()} */}
      <PageHeader
          className={"card-page-header"}
        //   title={
        //   <Button
        //     icon={<FilterOutlined/>}
        //     size={'small'}
        //     style={{margin: 3}}
        //     onClick={() => {
        //       setFilterDialog(true)
        //     } }>
        //     Filter
        // </Button>
        // }
        //   onBack={() => window.history.back()}
          title="Anggota IKAI"
          extra={[
              <Select
                  placeholder={'Pilih Level'}
                  allowClear={true}
                  style={{ width: 150, marginRight: "12px"}} onChange={(val) => {
                  store.membership.query["level"] = val
                  fetchData()
              }}>
                  {level?.map(val => (
                      <Option key={val.value}>{val.name}</Option>
                  ))}
              </Select>,
            <Select
                placeholder={'Pilih Wilayah'}
                allowClear={true}
                style={{ width: 150 }} onChange={(val) => {
              store.membership.query["membership_type_id"] = val
              fetchData()
            }}>
              {store.wilayah.data?.map(val => (
                  <Option key={val.id}>{val.name}</Option>
              ))}
            </Select>,
              <Search allowClear={true} placeholder="Pencarian Anggota" onSearch={onSearch} style={{ fixed: 'left',width: 150 }} />,
            <Button
              key="1"
              type="primary"
              onClick={() => {
                setInitialData({});
                setShowModuleFormModal(true);
              }}
            >
              <PlusOutlined /> Tambah
            </Button>,
            <Button
                onClick={() => {
                    const data = _.map(store.membership.data, val=>{
                        return {
                            id: val.id,
                            name: val.fullName,
                            npa: val.npa,
                            title: val.title,
                            email: val.email,
                            image: val.image_url,
                            phone: val.phone,
                            "Izin Praktik Konselor (IKAI)": val.practicePermitNo,
                            "Pengalaman Konseling":val.experienceYear,
                            "Nomor Serkom": val.serkomNo,
                            "Masa Berlaku Serkom": val.serkomEndDate,
                            status: val.status,
                            order: val.order
                        }
                    })
                    const fileName = 'list_anggota_ikai_(page_' + store.membership.query.page + ")";
                    const exportType =  exportFromJSON.types.xls

                    exportFromJSON({ data, fileName, exportType })
                }
                }
            >
                <DownloadOutlined />
            </Button>
          ]}
        />
        {!store.membership.isLoading && store.membership.data ? store.membership.data.length > 0 ? (
          <><Table
              pagination={{
                total:store.membership.maxLength,
                defaultCurrent:1,
                defaultPageSize: 10,
                showSizeChanger: true,
                current: store.membership.query.page,
                pageSize: store.membership.query.pageSize,
                onShowSizeChange: (_, pageSize) =>{
                  store.membership.query.pageSize=pageSize
                  store.membership.query.page=1
                  fetchData()
                },
                onChange: (page) => {
                  store.membership.query.page=page
                  fetchData()
                },
                pageSizeOptions: ['10', '25', '50', '100', '1000']}}

            scroll={{x: 1300,y: 'calc(100vh - 4em)'}}
            hasEmpty
            size={"small"}
            // scroll={{ x: 1300, y: 'calc(100vh - 4em)'  }}
            dataSource={!store.membership.isLoading && store.membership.data ? store.membership.data || [] : []}
            loading={store.membership.data.length === 0}
            columns={tableColumns}
          />
          </>
         ) : (
          <div
            style={{
              minHeight: "calc(100vh - 450px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Empty />
          </div>
         ):false}
      </Card>

      {showModuleFormModal && (
        <MembershipForm
          visible={showModuleFormModal}
          onSubmit={async (data) => {
            onSubmit(data)
            setConfirmLoading(true);
            console.log(data)
            let result;
            try {

              const body = {
                "first_name": data.fullName,
                "last_name": '',
                "npa": data.npa,
                "title": data.title,
                "email": data.email,
                "image_url": data.image_url,
                "phone": data.phone,
                "serkom_number": data.serkom_number,
                "serkom_end_date": data.serkomEndDate,
                "experience_year_start": data.experienceYearStart,
                "membership_type_id": data.membeshipTypeId,
                "membership_type_name": "IKAI " + _.get(_.filter(store.wilayah.data, (val) =>  {
                  if(val['id'] == data.membeshipTypeId){
                    return true;
                  } else {
                    return false
                  }
                }), '0.name', null),
                "practice_permit_number": data.practice_permit_number,
                "status": data.status ? 'active': 'inactive',
                  "order": data.order
              }

              if(data.id) {
                result = await store.membership.edit(data.id, body)
              } else {
                result = await store.membership.add(body)
              }

              console.log(result)

              if(result.body.status == 200) {
                message.success('Data Tersimpan!')
              } else {
                message.error('Data Gagal Tersimpan')
              }
              fetchData()
            } catch (e) {
              message.error('ERROR: ' + e.message)
            }

            setConfirmLoading(false);
            setShowModuleFormModal(false);
          }}
          onCancel={() => {
            setShowModuleFormModal(false);
          }}
          confirmLoading={confirmLoading}
          initialData={initialData}
        />
      )}
    {/* </Spin> */}
    </div>
  );
});
