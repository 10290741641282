import {Form, Input, Modal, Select, Row, Col, message, Switch, DatePicker, Upload, Progress} from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../utils/useStores";
import {appConfig} from "../../config/app";
import * as _ from "lodash";
import moment from "moment";
import {http} from "../../utils/http";
const { Option } = Select;


export const MembershipForm = observer(({visible, onSubmit, onCancel, confirmLoading, initialData}) => {
	const [form] = Form.useForm();
	const store = useStore();
	const[check, setCheck] = useState(true)
	const [imageUrl, setImageUrl ] = useState('');
	const [loadingImage, setLoadingImage ] = useState(false);
	const [progress, setProgress] = useState(0);


	useEffect(() => {
		init();
		setFieldValue()
	}, []);

	async function init() {
		fetchData()
	}
	async function fetchData() {
		console.log('Get ALL Membership Datas')
		if(store.membership.data) return;
		store.membership.getAll()
		console.log({roleData: store.membership.data}, "Anggota Ikai Data");
	}

	function onChange(checked) {
		setCheck(checked)
	  }

	function setFieldValue(){
		if(initialData.id) {
			// setCheck(initialData.training_status)
			setImageUrl(initialData.image_url)
			form.setFieldsValue({status: initialData.status == 'active'?true: false })
		}
	}


	const uploadButton = (
		<div>
			{loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	function beforeUpload(file) {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
		}
		return isJpgOrPng && isLt2M;
	}

	const handleChange = info => {
		if (info.file.status === 'uploading') {
			// this.setState({ loading: true });
			setLoadingImage('true')
			return;

		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			// getBase64(info.file.originFileObj, imageUrl => {
			// 	setLoadingImage(false)
			// 	// setImageUrl(imageUrl)
			// 	console.log('info.file.originFileObj', imageUrl)
			// });
		}
	};

	const uploadImage = async options => {
		const { onSuccess, onError, file, onProgress } = options;

		const fmData = new FormData();
		const config = {
			headers: { "content-type": "multipart/form-data" },
			onUploadProgress: event => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				setProgress(percent);
				if (percent === 100) {
					setTimeout(() => setProgress(0), 1000);
				}
				onProgress({ percent: (event.loaded / event.total) * 100 });
			}
		};
		fmData.append("image", file);
		try {
			const res = await http.upload(file)
				.then(response => {


					return response
				}).catch(e=> {
					return e
				})

			console.log(appConfig.apiUrl + '/api/v1' + res.body.path)
			setImageUrl(appConfig.apiUrl + '/api/v1' + res.body.path)
			form.setFieldsValue({image_url: appConfig.apiUrl + '/api/v1' + res.body.path})
			onSuccess("Ok");

			console.log("server res: ", res);
		} catch (err) {
			console.log("Eroor: ", err);
			const error = new Error("Some error");
			onError({ err });
		}
	};

	return <Modal
		maskClosable={false}
		visible={visible}
		width={'448px'}
		closable={false}
		bodyStyle={{background: '#f7fafc'}}
		title="Tambah Anggota"
		okText="Save"
		cancelText="Cancel"
		onCancel={() => {
			form.setFieldsValue({});
			onCancel();
		}}
		confirmLoading={confirmLoading}
		destroyOnClose={true}
		onOk={() => {
			form
				.validateFields()
				.then(values => {
					form.resetFields();
					onSubmit(values);
					form.setFieldsValue({});
				})
				.catch(info => {
					console.log('Validate Failed:', info);
				});
		}}
	>

		<Form
			form={form}
			layout="vertical"
			className={'custom-form'}
			name="form_in_modal"
			initialValues={initialData}

			onValuesChange={(values) => {
				console.log({values}, 'values -> ')

			}}
		>
			<Form.Item name='id' style={{display:'none'}}>
				<Input value={initialData.id}/>
			</Form.Item>

			<Form.Item
				name='npa'
				label="NPA"
				rules={[
					{
						required: true,
						message: 'Please input NPA!',
					},
				]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				name='fullName'
				label="Full Name"
				rules={[
					{
						required: true,
						message: 'Please input Full Name!',
					},
				]}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				name='image_url_upload'
				label="Image"
				rules={[
				]}
			>
				<Upload
					name="image_url_upload_container"
					listType="picture-card"
					className="avatar-uploader"
					showUploadList={false}
					customRequest={uploadImage}
					// action="https://anggota.ikai.or.id/api/v1/files"
					beforeUpload={beforeUpload}
					onChange={handleChange}
				>
					{imageUrl ? <img src={imageUrl} alt="Image" style={{ width: '100%' }} /> : uploadButton}
				</Upload>
				{progress > 0 ? <Progress percent={progress} /> : null}
			</Form.Item>

			<Form.Item name='image_url'
					   label={"URL Gambar"}
					   style={{display:'none'}}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				name='title'
				label="Title"
				rules={[
					{
						required: true,
						message: 'Please input title!',
					},
				]}
			>
				<Input/>
			</Form.Item>
			<Form.Item
				name='membeshipTypeId'
				label="Keanggotaan"
				rules={[
					{
						required: true,
						message: 'Please input wilayah keanggotaan!',
					},
				]}
			>
				<Select
					placeholder={'Pilih Wilayah'}
					allowClear={true}
					style={{ width: 200 }}
					>
					{store.wilayah.data?.map(val => (
						<Option key={val.id}>{val.name}</Option>
					))}
				</Select>
			</Form.Item>

			<Form.Item
				name='email'
				label="Email"
				rules={[
				]}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				name='phone'
				label="Phone"
				rules={[
				]}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				name='practicePermitNo'
				label="Izin Praktik Konselor (IKAI)"
				rules={[
				]}
			>
				<Input/>
			</Form.Item>

			{/*<Form.Item*/}
			{/*	name='experienceYear'*/}
			{/*	label="Pengalaman Konseling"*/}
			{/*	rules={[*/}
			{/*	]}*/}
			{/*>*/}
			{/*	<Input/>*/}
			{/*</Form.Item>*/}

			<Form.Item
				name='experienceYearStart'
				label="Pengalaman Konseling Dari Tahun"
				rules={[
				]}
			>
				<DatePicker picker="year" />
			</Form.Item>

			<Form.Item
				name='serkomNo'
				label="Nomor Serkom"
				rules={[
				]}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				name='serkomEndDate'
				label="Masa Berlaku Serkom"
				rules={[
				]}
			>
				<DatePicker
					allowClear={true} />
			</Form.Item>

			<Form.Item
				name="status"
				initialValue={false}
				label="Status Anggota"
			>
				<Switch checkedChildren="Active" unCheckedChildren="Non Active"  />
			</Form.Item>

			<Form.Item
				name='order'
				label="Urutan Ke-"
				rules={[
				]}
			>
				<Input/>
			</Form.Item>
			</Form>
	</Modal>
});
