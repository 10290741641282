import React, {useEffect, useState} from "react";
import {Menu, Modal, Form,Button, message} from "antd";
import {Link} from "react-router-dom";
import {CalendarOutlined, HomeOutlined, BookOutlined, AuditOutlined, UserOutlined, QrcodeOutlined, DownOutlined, SolutionOutlined, WalletOutlined, CopyOutlined, ApartmentOutlined} from "@ant-design/icons";
import {observer} from "mobx-react-lite";
import {useStore} from "../../utils/useStores";
import QRCode from "qrcode.react";
import { appConfig } from "../../config/app";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const saveSvgAsPng = require('save-svg-as-png')

const {SubMenu} = Menu;

export const MenuList = observer((props, initialData, confirmLoading) => {
	const store = useStore();
	const [form] = Form.useForm();

	useEffect(() => {
	}, []);

	const [setKeys, setSetKeys] = useState(["dashboard"]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [showPartnerSelection, setShowPartnerSelection] = useState(false);
	const [state, setState]= useState({
		value: '',

	})

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {

        saveSvgAsPng.saveSvgAsPng(document.getElementById("qrcode-container"),
            "qrabsen.png",
            {
				scale: 15,
            }
        );
	};

	const handleCancel = () => {
        setIsModalVisible(false);
    };

	return (
		<Menu
			// defaultOpenKeys={["sub4"]}
			theme="light"
			style={{
				backgroundColor: "transparent",
				borderRightWidth: 0,
				fontWeight: 400,
				paddingLeft: 0,
			}}
			onClick={({keyPath, item}) => {
				store.ui.toggleLeftDrawerIsShown();
			}}
			mode="inline"
			selectedKeys={setKeys}
			onSelect={({setKeys, item, selectedKeys}) => setSetKeys(selectedKeys)}
			overflowedIndicator={0}
			forceSubMenuRender={true}
		>
				{/*<Menu.Item key="dashboard">*/}
				{/*	<Link to={'/app/dashboard'}>*/}
				{/*		<HomeOutlined/>*/}
				{/*		<span>Dashboard</span>*/}
				{/*	</Link>*/}
				{/*</Menu.Item>*/}

				<Menu.Item key="membership">
					<Link to={'/app/membership'}>
						<CopyOutlined />
						<span>Anggota IKAI</span>
					</Link>
				</Menu.Item>

			{(store.role == "admin") &&
				<Menu.Item key="wilayah">
					<Link to={'/app/wilayah'}>
						<AuditOutlined/>
						<span>Wilayah  IKAI</span>
					</Link>
				</Menu.Item>
			}

			<Menu.Divider style={{background: "#c7ccd9", paddingTop: 1, width: '80%', marginLeft:'12%'}}/>
			{(store.userData.role_name == "Superadmin") &&
				<Menu.Item key="vendor">
					<Link to={'/app/vendor'}>
						<BookOutlined />
						<span>Vendor</span>
					</Link>
				</Menu.Item>
			}


				<Menu.Item key="users">
					<Link to={'/app/users'}>
						<UserOutlined />
						<span>Users</span>
					</Link>
				</Menu.Item>

				{/*<Modal*/}
				{/*	maskClosable={false}*/}
				{/*	visible={isModalVisible}*/}
				{/*	width={'448px'}*/}
				{/*	closable={false}*/}
				{/*	bodyStyle={{background: '#f7fafc'}}*/}
				{/*	title="QR Absen"*/}
				{/*	okText="Save"*/}
				{/*	cancelText="Cancel"*/}
				{/*	onCancel={handleCancel}*/}
				{/*	confirmLoading={confirmLoading}*/}
				{/*	destroyOnClose={true}*/}
				{/*	onOk={handleOk}*/}
				{/*	footer={*/}
				{/*		[*/}
				{/*			<Button onClick={handleCancel}>*/}
				{/*				Close*/}
				{/*			</Button>,*/}
				{/*			<Button onClick={handleOk}>*/}
				{/*				Save*/}
				{/*			</Button>,*/}
				{/*			//  <CopyToClipboard text={state.value}*/}
				{/*			//  onCopy={() => message.success("Link Absen Copied!")}>*/}
				{/*			//  <Button>Copy Link Absen</Button>*/}
				{/*		   // </CopyToClipboard>,*/}
				{/*			<Button type={'danger'}  onClick={()=>{*/}
				{/*				window.open('/absen')*/}
				{/*			}}>*/}
				{/*				Form Absen*/}
				{/*			</Button>,*/}



				{/*		]*/}
				{/*	}*/}
            	{/*>*/}


                {/*<div*/}
                {/*    style={{display:"flex", justifyContent:'center', alignItems:'center'}}>*/}
                {/*    /!* <QRCode value="https://ma-training.app.bangun-kreatif.com/absen" /> *!/*/}
                {/*    <QRCode*/}
				{/*		id={"qrcode-container"}*/}
				{/*		onClick={() => {*/}
				{/*			saveSvgAsPng.saveSvgAsPng(document.getElementById("qrcode-container"), ( "qrcode") + ".png")*/}
				{/*		}}*/}
				{/*		size={200}*/}
				{/*		renderAs={'svg'}*/}
				{/*		value={"https://ma-training.app.bangun-kreatif.com/absen"} />*/}

                {/*</div>*/}
		</Menu>
	);
});
