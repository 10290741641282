import React, {useEffect, useState} from "react";
import {AppRoutes} from "../../routes/app";
import {Button, Drawer, Layout, Menu, PageHeader, Popover, Typography,} from "antd";
import {createUseStyles} from "react-jss";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, useHistory,} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {UserOutlined} from "@ant-design/icons";
import {observer} from "mobx-react-lite";
import {useStore} from "../../utils/useStores";
import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";
import {MenuList} from "./MenuList";


const {Text, Paragraph} = Typography;
const {Header, Content, Sider} = Layout;


export const App = observer((props) => {
	let history = useHistory();
	const store = useStore();

	const mediaQuery = {
		isBigScreen: useMediaQuery({query: '(min-device-width: 1824px)'}),
		isDesktop: useMediaQuery({minWidth: 1024}),
		isTablet: useMediaQuery({minWidth: 768, maxWidth: 1023}),
		isMobile: useMediaQuery({maxWidth: 767}),
		isNotMobile: useMediaQuery({minWidth: 768}),
	};

	const useStyles = createUseStyles({
		logo: {
			paddingLeft: 20,
			marginBottom: 32,
		},
		logoFull: {
			borderRadius: 4,
			padding: 2,
			width: mediaQuery.isDesktop ? 145 : null,
			position: 'relative',
			marginTop: mediaQuery.isDesktop ? '-10.5px' : null,
		},
		hideSidebarButton: `SettingOutlined 
        font-size: 18px;
    padding: 0 24px;
    cursor: pointer;
     color: #fff;
    transition: color 0.3s;
    `,
		hideSidebarButtonHovered: {"&:hover": `color: #fff;`},
		sideFixed: {
			overflow: "auto",
			height: "100vh",
			position: "fixed",
			left: 0,
			zIndex: 99,
		},
		containFixed: {
			overflow: "auto",
			height: "100vh",
			position: "fixed",
			top: 48,
			width: "100vw",
		},
	});


	const buttonProfile = (
		<Button
			size={"default"}
			type={store.ui.mediaQuery.isDesktop ? "" : "link"}
			style={{marginRight: store.ui.mediaQuery.isDesktop ? 20 : 10}}
			icon={
				store.ui.mediaQuery.isDesktop ? (
					<UserOutlined style={{fontSize: "13px"}}/>
				) : (
					<UserOutlined style={{fontSize: "13px", color: "#5b5b5b"}}/>
				)
			}
		/>
	);

	const [clicked, setClicked] = useState(false);
	useEffect(() => {
		store.ui.setMediaQuery(mediaQuery);
	});
	const classes = useStyles();

	const profilePopover = (
		<Popover
			className={store.ui.mediaQuery.isDesktop ? "shadow" : null}
			autoAdjustOverflow={true}
			placement="bottomRight"
			content={
				<Menu
					type={"line"}
					inlineIndent={0}
					theme="light"
					style={{
						backgroundColor: "transparent",
						borderRightWidth: 0,
					}}
					mode="inline"
				>
					{/* <Menu.Item>
						<Link to="/app/profile">
							<span>Profile</span>
						</Link>
					</Menu.Item> */}
					<Menu.Item
						onClick={() => {
							store.authentication.logout();
							return history.push("/login");
						}}
					>
						<span>Sign out</span>
					</Menu.Item>
				</Menu>
			}
			title={
				<Text>
					{store.userData.email}{" "}
					<Paragraph style={{fontWeight: 400, paddingTop: 10}} type={"secondary-dark"}>
						{store.userData.name}
					</Paragraph>
				</Text>
			}
			trigger="click"
			visible={clicked}
			onVisibleChange={() => setClicked(!clicked)}
		>
			{buttonProfile}
		</Popover>
	);

	store.ui.pushRoute(history.location.pathname);

	return (
		<Layout
			theme={"light"}
			className={"transparent"}
			hasSider={store.ui.mediaQuery.isDesktop}
			style={{
				paddingLeft: mediaQuery.isBigScreen ? "calc(50vw - 695px)" : "0",
				display: "flex",
				// minWidth: 1024,
				width: "100%",
				height: "100%",
				position: "relative",
			}}
		>
			{store.ui.mediaQuery.isDesktop && (
				<Sider
					className={"transparent"}
					width={240}
					style={{
						overflowX: "hidden",
						bottom: 0,
						justifyContent: "flex-start",
						paddingTop: 20,
						paddingLeft: 20,
						position: "fixed",
						top: 0,
						zIndex: 10,
					}}
				>
					<div className={classes.logo}>
						<Paragraph
							style={{
								margin: 0,
								padding: 0,
								fontSize: 20,
								marginLeft: 5,
								fontWeight: 600,
								color: "#413d3e",
							}}
						>
							Anggota IKAI Web Admin
						</Paragraph>
					</div>
					<MenuList/>
				</Sider>
			)}

			{store.ui.mediaQuery.isMobile && (
				<Drawer
					title="Training MA Menu"
					placement={"left"}
					closable={false}
					width={"80%"}
					onClose={() => store.ui.toggleLeftDrawerIsShown()}
					visible={store.ui.leftDrawerIsShown}
					key={"dashboard-drawer"}
					bodyStyle={{
						padding: 0,
						paddingTop: 30,
					}}
				>
					<div
						style={{
							marginLeft: 0,
							paddingLeft: 0,
						}}
					>
						<MenuList/>
					</div>
				</Drawer>
			)}


			<Layout
				className={"transparent"}
				style={{
					position: "relative",
					display: "block",
					paddingLeft: store.ui.mediaQuery.isDesktop ? 240 : 0,
					height: "100vh",
				}}
			>
				<Header
					theme={"light"}
					className={store.ui.mediaQuery.isMobile ? "shadow" : null}
					style={{
						height: store.ui.mediaQuery.isDesktop ? 54 : 65,
						width: "100%",
						position: store.ui.mediaQuery.isDesktop ? "fixed" : "fixed",
						zIndex: 99,
						paddingLeft: store.ui.mediaQuery.isDesktop ? 14 : 0,
						paddingRight: store.ui.mediaQuery.isDesktop ? 14 : 0,
						backgroundColor: store.ui.mediaQuery.isMobile
							? "#fff"
							: "transparent",
						maxWidth: store.ui.mediaQuery.isDesktop ? 1024 : 768,
						top: store.ui.mediaQuery.isMobile ? 0 : 12,
					}}
				>
					{store.ui.mediaQuery.isMobile && (
						<div
							className={store.ui.mediaQuery.isMobile ? "shadow" : null}
							style={{
								top: 0,
								left: 0,
								paddingTop: 8,
								position: "relative",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "stretch",
								flexDirection: "column",
								// boxShadow: '0 7px 14px 0 rgba(60, 66, 87, 0.05), 0 3px 6px 0 rgba(0, 0, 0, 0.05)'
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									height: "100%",
									width: "100%",
									paddingLeft: store.ui.mediaQuery.isMobile ? null : 20,
									paddingRight: store.ui.mediaQuery.isMobile ? 0 : 20,
									paddingBottom: 4,
									marginTop: -5,
								}}
							>
								<Button
									type="link"
									icon={
										<MenuOutlined
											style={{
												fontSize: "12px",
												color: "#5b5b5b",
											}}
										/>
									}
									onClick={() => store.ui.toggleLeftDrawerIsShown()}
								/>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										alignItems: "stretch",
										padding: 0,
										margin: 0,
										paddingTop: 5,
									}}
								>
									<Paragraph
										style={{
											padding: 0,
											lineHeight: "18px",
											fontSize: 20,
											fontWeight: 600,
											color: "#333",
											textAlign: "center",
										}}
									>
										{"Anggota IKAI Web Admin"}
									</Paragraph>
								</div>
								{profilePopover}
							</div>
							<div
								style={{
									borderTopWidth: 0.5,
									borderTopColor: "#e3e3e3",
									borderTopStyle: "solid",
								}}
							/>

						</div>
					)}

					{store.ui.mediaQuery.isDesktop && (
						<div
							className={["transparent"].join(" ")}
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								height: "100%",
								maxWidth: 1024,
								minWidth: 768,
								width: "calc(100vw - 220px)",
							}}
						>
							<div
								style={{
									flexDirection: "row",
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
									width: "100%",
								}}
							>
								{/*<BreadcrumbComponent*/}
								{/*    style={{*/}
								{/*        fontSize: 11,*/}
								{/*        fontWeight: 600,*/}
								{/*    }}*/}
								{/*    params={store.ui.params}*/}
								{/*    baseUrl={store.ui.baseUrl}*/}
								{/*    data={store.ui.routes}*/}
								{/*/>*/}
							</div>
							{profilePopover}
						</div>
					)}
				</Header>
				<Layout
					className={["transparent"].join(" ")}
					tabIndex={"-1"}
					style={{
						zIndex: 0,
						display: "flex",
						// overflowX: store.ui.mediaQuery.isMobile ? 'auto' : "hidden",
						// paddingLeft: 8,
						// paddingRight: 8,
						width: "calc(90vw - 220px)",
						height: "100%",
					}}
				>
					<Content
						className={["transparent"].join(" ")}
						style={{
							// maxHeight: 'calc(100vh - 98px)',
							// paddingBottom: 48,
							padding: 0,
							margin: 0,
							position: "relative",
							marginTop: store.ui.mediaQuery.isDesktop ? 78 : 72,
							// overflow: 'auto',
							overflowX: store.ui.mediaQuery.isMobile ? "auto" : null,
							// minWidth: 768,
							maxWidth: 1024,
							// minWidth: 768,
							width: store.ui.mediaQuery.isDesktop
								? "calc(100vw - 210px)"
								: "calc(100vw)",
							zIndex: 22,
							// height: `calc(100vh - ${store.ui.mediaQuery.isDesktop ? 78 : 71}px)`,
							height: `calc(100vh - ${
								store.ui.mediaQuery.isDesktop ? 78 : 71
							}px)`,
							// paddingLeft: 8,
							// paddingRight: 8
						}}
					>
						{store.ui.mediaQuery.isDesktop && (
							<PageHeader
								style={{
									padding: 8,
									paddingLeft: 18,
									marginBottom: 0,
									backgroundColor: "transparent",
								}}
								title={""}
							>
							</PageHeader>
						)}
						<AppRoutes/>
					</Content>
				</Layout>
			</Layout>
		</Layout>
	);
});
