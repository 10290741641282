import {action, computed, observable} from "mobx";

import {Authentication} from "./authentication";
import {MembershipStore} from "./membership";
import {WilayahStore} from "./wilayah";
import {UiStore} from "./ui";

export class Store {

	authentication = new Authentication(this);
	membership = new MembershipStore(this);
	wilayah = new WilayahStore(this);
	ui = new UiStore(this);

	@observable token = "";
	@observable role = "";
	@observable isLogin = false

	constructor(token) {
		this.setToken(token);
	}

	@computed get isLoggedIn() {
		return !!this.token;
	}

	@computed
	get userData() {
		const token = localStorage.getItem("id_token");
		if (!token) {
			return {
				id: "",
				roles: "staff",
				username: "",
				email: "",
			};
		}

		let tokenData = JSON.parse(atob(token.split(".")[1]));

		return tokenData;
	}

	@action
	setToken(token) {
		this.token = token;
		if (token) {
			localStorage.setItem("id_token", token);
		} else {
			localStorage.removeItem("id_token");
		}
	}

	@action
	setRole(role){
		this.role = role;
		if (role) {
			localStorage.setItem("role", role);
		} else {
			localStorage.removeItem("role");
		}
	}

	@action
	clearState(){
		this.membership.clearData();
		this.wilayah.clearData();
	}

	@action
	setIsLogin(){
		this.isLogin = true
	}
}
