import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../utils/useStores";
import {RadialChart} from "./RadialChart";
import {EmptyCard} from "./EmptyCard";
import {
    Row,
    Col,
    Avatar,
    Button,
    Progress,
    Typography,
    Statistic,
    Card,
    Empty,
    PageHeader,
    List,
    Divider,
    Dropdown,
    Table,
    Menu, Space, Select,Modal,Form, DatePicker, Breadcrumb
} from "antd";
import {createUseStyles} from "react-jss";
import moment from 'moment';
import { BreadcrumbComponent } from "../../component/Breadcrumb";
import {useQuery, gql, useMutation} from "@apollo/client";

const {Title, Paragraph} = Typography;
const useStyles = createUseStyles({
	gridStyle: {
		overflow: 'hidden',
		position: 'relative',
		padding: '16px 15px 8px',
		// height: 200,
		transition: 'transform .4s',
		boxShadow: 'inset -1px 0 #e3e8ee, inset 0 -1px #e3e8ee'

	},
	gridStyleHeaderRight: {
		overflow: 'hidden',
		// height: 200 / 2,
		position: 'relative',
		padding: '16px 15px 8px',
		transition: 'transform .4s',
		boxShadow: 'inset 0 -1px #e3e8ee'
	},
});

const {Option} = Select;

const optionFilter = [
    {
        key: "today",
        value: "Today",
      },
      {
        key: "last_3_days",
        value: "Last 3 days",
      },
      {
        key: "last_6_month",
        value: "Last 6 months",
      },
      {
        key: "last_12_month",
        value: "Last 12 months",
      },
      {
        key: "all_time",
        value: "All time",
      },
      // {
      //   key: "custom_date",
      //   value: "Custom Date",
      // },
];

const optionList = optionFilter.map((i, index) => {
    return (
      <Option key={index} value={i.key}>
        {i.value}
      </Option>
    );
  });


export const Dashboard = observer((initialData) => {
  const store = useStore();
  const [form] = Form.useForm();
	const classes = useStyles();
	const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState();
  const [summary, setSummary] = useState({});
  const [periode, setPeriode] = useState('today');
  const [attend, setAttend] = useState(1/2 *100)
  const [notAttend, setNotAttend] = useState(1/2 *100)
  

	useEffect(() => {
    // getData()
  }, [periode]);
  
  const {loading, error, data, refetch} = useQuery(gql`
      query($period: String!){
        getDashboard(period : $period){
          module
          participant
          attendedClassParticipant
          notAttendedClassParticipant
        }
        getDashboardToday{
          module
          participant
          attendedClassParticipant
          notAttendedClassParticipant
        }
      }`, {
        variables: {
            period:periode
      }
    });

   

	const dataPerPeriodToday = [
        {
            title : 'Modules Count',
            value : !loading && data ? data.getDashboardToday.module :0
            // extra : ''
        },
        {
            title : 'Participants Count',
            value : !loading && data ? data.getDashboardToday.participant :0,
            // extra : ''
        },
        {
            title : 'AttendedParticipants Count',
            value : !loading && data ? data.getDashboardToday.attendedClassParticipant :0,
            // extra : 'All Deposit'
        },
        {
            title : 'Absent Participants Count',
            value : !loading && data ? data.getDashboardToday.notAttendedClassParticipant :0,
            // extra : 'Estimated future pay in'
        },
  ];
  const dataPerPeriod = [
    {
        title : 'Modules Count',
        value : !loading && data ? data.getDashboard.module :0
        // extra : ''
    },
    {
        title : 'Participants Count',
        value : !loading && data ? data.getDashboard.participant :0,
        // extra : ''
    },
    {
      title : 'Attended Participants Count',
      value : !loading && data ? data.getDashboard.attendedClassParticipant :0,
      // extra : 'All Deposit'
  },
  {
      title : 'Absent Participants Count',
      value : !loading && data ? data.getDashboard.notAttendedClassParticipant :0,
      // extra : 'Estimated future pay in'
  },
];

const dataChart = [
  {
    title : 'Attended Participants Count',
    value :!loading && data ? Number(data.getDashboard.attendedClassParticipant) / (Number(data.getDashboard.attendedClassParticipant) + Number(data.getDashboard.notAttendedClassParticipant) ) *100 :0 ,
  },
  {
    title : 'Absent Participants Count',
    value : !loading && data ? Number(data.getDashboard.notAttendedClassParticipant) / (Number(data.getDashboard.attendedClassParticipant) + Number(data.getDashboard.notAttendedClassParticipant) ) *100 :0 ,
  },
];


  
  function modalCustomDate(){
    return <Modal
    title="Select Cutom Date"
    visible={visible}
    onOk={() => {
  form
    .validateFields()
    .then(values => {
      form.resetFields();
      setPeriod(values);
      form.setFieldsValue({});
    })
    .catch(info => {
      console.log('Validate Failed:', info);
    });
}}
    onCancel={()=>{toggle()}}
  >
      <Form form={form} initialValues={initialData}>
            <Row className="flex" justify="space-between">
                <Col style={{width:'100%'}}>
                    <Form.Item name="custom_date" rules={[{required:true, message:"Please Input Custom Date"}]}>
                        <DatePicker style={{width:'98%'}}/>
                    </Form.Item>
                </Col>
            </Row>
      </Form>
  </Modal>
}

	function toggle(){
        setVisible(!visible);
    }

    function setPeriod(e){
        let date = moment(e.custom_date).format('YYYY-MM-DD')
        setFilter(date);
        toggle()
    }

	return (
		<div className="site-card-wrapper">
      {console.log(!loading && data ? data.getDashboard :'', "apapaa")}
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="">Dashboard</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row style={{justifyContent :'space-between'}} >
        {dataChart.map(d=>{
           console.log(d, "chart participant ->")
          return <Col lg={12} style={{justifyContent:'center', 'textAlign' :'center', padding:'10px' }}>
					   <Card
                bordered={false}
                style={{overflow: 'hidden', marginTop: 20}}
                className={"shadow"}
                bodyStyle={{background: "#f7fafc", marginRight: '-1px', padding: 0}}
              >
           <Card.Grid hoverable={false} className={classes.gridStyleHeaderRight} style={{width: '100%', paddingTop:'2.5vh', paddingBottom:'2.5vh'}}>
              <Paragraph  style={{fontSize: 13,width:'100%', marginTop: 3, marginBottom: 0, overflowWrap:'normal'}}>{d.title}</Paragraph>
              <Progress style={{width:'100%'}} format={(p) => `${isNaN(d.value)? 0 : parseInt(d.value)}%`} percent={isNaN(d.value)? 0 :d.value} type={"dashboard"} />
           </Card.Grid>
           </Card> 
          </Col>})}
        </Row>
			<Card
				bordered={false}
				style={{overflow: 'hidden', marginTop: 20}}
				className={"shadow"}
				bodyStyle={{background: "#f7fafc", marginRight: '-1px', padding: 0}}
			>
				<PageHeader
					className={'card-page-header'}
					ghost={false}
					// onBack={() => window.history.back()}
					title="Today"
					// subTitle="This is a subtitle"
				>
				</PageHeader>
				{dataPerPeriodToday.map(d=>{
         
					return  <Card.Grid hoverable={false} className={classes.gridStyleHeaderRight} style={{width: '25%', paddingTop:'2.5vh', paddingBottom:'2.5vh'}}>
              <Paragraph  style={{fontSize: 13,width:'100%', marginTop: 3, marginBottom: 0, overflowWrap:'normal'}}>{d.title}</Paragraph>
              <Statistic valueStyle={{ fontSize: 25, color: "#c67905" }} value={parseInt(d.value) || 0}/>
              <Paragraph style={{fontSize: 10, marginTop: 3, marginBottom: 0,overflowWrap:'normal'}}>{d.extra}</Paragraph>
          </Card.Grid>

				})}
			</Card>
      
      {modalCustomDate()}

      <Card
				bordered={false}
				style={{overflow: 'hidden', marginTop: 20}}
				className={"shadow"}
				bodyStyle={{background: "#f7fafc", marginRight: '-1px', padding: 0}}
			>
				<PageHeader
					className={'card-page-header'}
					ghost={false}
					// onBack={() => window.history.back()}
					title="Reports overview"
					// subTitle="This is a subtitle"
				>
					<Space>
						<Select
              size={"small"}
              defaultValue={'today'}
							onChange={async (value) => {
                console.log(value === 'custom_date');
								value === 'custom_date' ? toggle() :
                await setPeriode(value);
                refetch()
							}}
							style={{ minWidth: 80, marginTop: 8 }}
							value={filter}
							>
								{optionList}
						</Select>
					</Space>
				</PageHeader>
        {dataPerPeriod.map(d=>{
					return  <Card.Grid hoverable={false} className={classes.gridStyleHeaderRight} style={{width: '25%', paddingTop:'2.5vh', paddingBottom:'2.5vh'}}>
						<Paragraph  style={{fontSize: 13,width:'100%', marginTop: 3, marginBottom: 0, overflowWrap:'normal'}}>{d.title}</Paragraph>
						<Statistic valueStyle={{ fontSize: 25, color: "#c67905" }} value={d.value || 0}/>
						<Paragraph style={{fontSize: 10, marginTop: 3, marginBottom: 0,overflowWrap:'normal'}}>{d.extra}</Paragraph>
				</Card.Grid>
				})}

			</Card>
    
			

				
			 
		</div>
	);
});
