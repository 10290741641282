import {action, observable} from 'mobx'

export class UiStore {

	firstLoad = true;

	@observable baseUrl = '/app';
	@observable leftDrawerIsShown = false;
	@observable routes = [];
	@observable routeNames = [];
	@observable mediaQuery = {};
	@observable params = {};

	constructor(context) {
		this.context = context;
	};

	@action
	pushRoute(data) {
		this.routes.push(data);
	};

	@action
	toggleLeftDrawerIsShown() {
		this.leftDrawerIsShown = !this.leftDrawerIsShown;
	}

	@action
	setParams(data) {
		this.params = data;
	};

	@action
	setMediaQuery(data) {
		console.log(data, this.mediaQuery, "mediadata");
		if (this.mediaQuery.isDesktop !== data.isDesktop || this.mediaQuery.isMobile !== data.isMobile) {
			this.mediaQuery = data;
		}
	};
}
