import React, { Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { useStore } from "../utils/useStores";

import {Dashboard} from "../pages/Dashboard";
import {MembershipList} from "../pages/MembershipList";
import {WilayahList} from "../pages/Wilayah";
import {Users} from "../pages/Users";
import history from './history';

export const AppRoutes = () => {
  const store = useStore();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper-app"
      >
		  <Route history={history} path={"/app/dashboard"}>
			  <MembershipList/>
		  </Route>

		  <Route history={history} path={"/app/membership"}>
			  <MembershipList/>
		  </Route>

		  <Route history={history} path={"/app/users"}>
			  <Users/>
		  </Route>

		  <Route history={history} path={"/app/wilayah"}>
			  <WilayahList/>
		  </Route>


        <Route path="/app" exact>
          <Redirect to={'/app/membership'} />
        </Route>
      </AnimatedSwitch>
    </Suspense>
  );
};
