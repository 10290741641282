import React, {useState} from "react";
import {observer} from 'mobx-react-lite';
import {useStore} from "../../utils/useStores";
import {Button, Card, Checkbox, Col, Form, Input, Row, Typography, message} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {createUseStyles} from "react-jss";
import {useHistory} from "react-router-dom";
import * as _ from 'lodash';

const useStyles = createUseStyles({
	logo: {
		// height: 30,
		paddingLeft: 20,
		marginBottom: 16
	},
	logoFull: {
		height: 80,
		width: 300,
		marginBottom: 8
	},

});
const {Text, Paragraph, Title} = Typography;
const layout = {
	labelCol: {span: 8},
	wrapperCol: {span: 8},
};
const tailLayout = {
	wrapperCol: {offset: 6, span: 8},
};

export const Login = observer(() => {
	const [isLogin, setIsLogin] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, handleEmail] = useState("");
	const [password, handlePassword] = useState("");
	const [remember, handleRemember] = useState(false);
	const store = useStore();

	let history = useHistory();

	const onFinish = values => {
		console.log('Received values of form: ', values);
		enterLoading(values).then(res => {
			console.log("Success Login - ", res);
		}).catch((error) => {
			console.log({error}, " Login Error");
			// Some error occurred, you can inspect the code: error.code
			// Common errors could be invalid email and invalid or expired OTPs.
		});
	};


	const classes = useStyles();

	const enterLoading = async (props) => {
		setLoading(true);
		const {email, password} = props;
		console.log({email, password}, "loginUsingEmail started");

		try {
			await store.authentication.login({email, password});
			setLoading(false);
			return history.push("/app/dashboard")
		} catch (err) {
			message.error(_.get(err, 'response.body.message'));
			console.log(err.response.body.message, "loading falied error");
			setLoading(false);
		}
	};


	return <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}>
		<Row justify={'center'}>
			<Col>
				<div style={{
					display: 'flex',
					justifyContent: 'flex-start',
					marginTop: '5vh',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
						<Paragraph
							style={{
								margin: 0,
								padding: 0,
								fontSize: 20,
								marginLeft: 5,
								fontWeight: 600,
								color: "#413d3e",
							}}
						>
							Anggota IKAI Web Admin
						</Paragraph>
					</div>
					<Card
						style={{width: 320, textAlign: 'center'}}
						headStyle={{fontSize: 13, fontWeight: 200}}
						className={"shadow"}
						bordered={true}
						title={'Sign in to your account'}
					>
						<Form
							layout={'vertical'}
							name="normal_login"
							className="login-form"
							initialValues={{email, password, remember}}
							onFinish={onFinish}
						>
							<Form.Item
								label="Email"
								name="email"
								size={'large'}
								rules={[{required: false, message: 'Please input your Username!'}]}
							>
								<Input
									prefix={<UserOutlined className="site-form-item-icon"/>}
									type="text"
									placeholder="Email"/>
							</Form.Item>

							<Form.Item
								style={{
									marginBottom: 0,
								}}
								label="Password"
								name="password"
								size={'large'}
								rules={[{required: false, message: 'Please input your Password!'}]}
							>
								<Input.Password
									prefix={<LockOutlined className="site-form-item-icon"/>}
									type="password"
									placeholder="Password"
								/>
							</Form.Item>
							<Form.Item
								style={{
									marginTop: 0,
									marginBottom: 20,
									padding: 0
								}}
								// label="Password"
								name="forgot-password"
								size={'small'}
								rules={[{required: false, message: 'Please input your Password!'}]}
							>
								<a className="login-form-forgot" href="">
									Forgot password
								</a>
							</Form.Item>

							<Form.Item
								style={{
									marginBottom: 5,
									textAlign: 'left'
								}}>
								<Form.Item name="remember" valuePropName="checked" noStyle>
									<Checkbox value={remember} onChange={val => handleRemember(val)}>Remember
										me</Checkbox>
								</Form.Item>
							</Form.Item>

							<Form.Item
								style={{
									marginBottom: 0,
								}}>
								<Button type="primary"
										block
										loading={loading}
										htmlType="submit"
										size={'large'}
										// onSubmit={enterLoading}
									// onClick={enterLoading}
										className="login-form-button">
									Sign In
									{/*<Link to={LINKS.DASHBOARD} innerRef={node => {*/}
									{/*    // `node` refers to the mounted DOM element*/}
									{/*    // or null when unmounted*/}
									{/*}}>Sign In</Link>*/}
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</div>
			</Col>
		</Row>

	</div>;
});
