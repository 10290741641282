import { action, computed, observable } from "mobx";
import { http } from "../utils/http";
import * as qs from "querystring";

export class MembershipStore {
    baseUrl = "/api/v1/membership";

    @observable data = [];
    @observable dataSummary = '';
    @observable isLoading = true;
    @observable filterData = [];
    @observable maxLength = 0;
    @observable query = {
        page: 1,
        pageSize: 10,
        isAdmin: true
    };

    constructor(context) {
        this.context = context;
    }

    @action
    clearData(){
        this.filterData=[];
        this.query = {
            page: 1,
            pageSize: 10,
            isAdmin: true
        };
        this.data = [];
        this.dataSummary="";
    }

    @action
    edit(id, data) {
        this.isLoading = true;
        console.log('EDIT ', data)
        return http
            .put(this.baseUrl + '/' + id, data)
            .then((res) => {
                console.log({ res }, "MembershipStore -> Edit");
                this.isLoading = false;
                return res;
            })
            .catch((err) => {
                this.isLoading = false;
                console.log("Error!", err);
                throw err;
            });
    }

    @action
    add(data) {
        this.isLoading = true;
        return http
            .post(this.baseUrl, data)
            .then((res) => {
                console.log({ res }, "MembershipStore -> Delete");
                this.isLoading = false;
                return res;
            })
            .catch((err) => {
                this.isLoading = false;
                console.log("Error!", err);
                throw err;
            });
    }

    @action
    delete(id){
        this.isLoading = true;
        return http
            .del(this.baseUrl + '/'+ id)
            .then((res) => {
                console.log({ res }, "MembershipStore -> Delete");
                this.isLoading = false;
                return res;
            })
            .catch((err) => {
                this.isLoading = false;
                console.log("Error!", err);
                throw err;
            });
    }

    @action
    getAll(filter) {
        if(filter != null){
            this.query.filter = filter;
        }
        this.isLoading = true;
        return http
            .get(this.baseUrl+  '?' + qs.stringify(this.query))
            .then((res) => {
                console.log({ res }, "MembershipStore -> ");
                this.isLoading = false;
                this.data = res.body.data;
                this.maxLength = res.body.total_data
                return res;
            })
            .catch((err) => {
                this.isLoading = false;
                console.log("Error!", err);
                throw err;
            });
    }

}
