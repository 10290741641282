import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {useStore,parseJwt} from "../utils/useStores";
// import { isLogin } from '../utils';

const PrivateRoute = ({component: Component, ...rest}) => {
  const store = useStore();

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      store.token && (parseJwt(store.token)?.exp * 1000 > Date.now()) ?
      // true ?
        <Component {...props} />
        : <Redirect to="/login" />
    )} />
  );
};

export default PrivateRoute;
