import superagent from "superagent";
import {appConfig} from "../config/app";
import {store} from "./useStores";
// import {useHistory} from "react-router-dom";
// let { history } = this.props


let AuthIntercept = require('superagent-intercept')((err, res) => {
	// let history = useHistory();
	if (res.status == 401) {
		store.authentication.logout();
		window.location.replace("/");
	}
});

export const http = {

	get: (url, opts = {}) => {
		let apiUrl = opts?.apiUrl ? opts.apiUrl + url : appConfig.apiUrl + url;
		let q = superagent.get(apiUrl);
		if (store.token) {

			q.use(AuthIntercept)
			q = q.set({
				'Authorization': 'Bearer ' + store.token
			})
		}
		return q;
	},
	post: (url, body, opts) => {
		let apiUrl = opts?.apiUrl ? opts.apiUrl + url : appConfig.apiUrl + url;
		let q = superagent.post(apiUrl, body);
		if (store.token) {
			q.use(AuthIntercept)
			q = q.set({
				'Authorization': 'Bearer ' + store.token
			})
		}
		return q;
	},
	put: (url, body, opts) => {
		let apiUrl = opts?.apiUrl ? opts.apiUrl + url : appConfig.apiUrl + url;
		let q = superagent.put(apiUrl, body);
		if (store.token) {
			q.use(AuthIntercept)
			q = q.set({
				'Authorization': 'Bearer ' + store.token
			})
		}
		return q;
	},
	del: (url, opts) => {
		let apiUrl = opts?.apiUrl ? opts.apiUrl + url : appConfig.apiUrl + url;
		console.log(apiUrl, "q nya")
		let q = superagent.del(apiUrl);
		if (store.token) {
			q.use(AuthIntercept)
			q = q.set({
				'Authorization': 'Bearer ' + store.token
			})
		}
		return q;
	},
	upload: (file) => {
		const request = superagent
			.post(appConfig.apiUrl + '/api/v1/files')
			.attach('files', file);

		return request;
	},
	uploadAntd: (args) => {
		const file = args.file;
		const request = http.upload(file);
		request
			.on('progress', event => {
				args.onProgress(event);
			})
			.then(it => {
				args.onSuccess(it);
			}).catch(err => {
			args.onError(err);
		});

		return request;
	},

};
