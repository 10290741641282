import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  PageHeader,
  Table,
  Input,
  Select,
  Space,
  Typography,
  Modal,
  Form ,
  DatePicker,
  Breadcrumb,
  Empty,
  message
} from "antd";
import {
    FilterOutlined,
    PlusOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    LockOutlined
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import {useStore} from "../../utils/useStores";
import { UserForm } from "./UserForm";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import * as _ from "lodash";
import moment from "moment";
import Highlighter from "react-highlight-words";
import {Link} from "react-router-dom";

const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;

let selectedRole;
export const Users = observer((props) => {
  const { RangePicker } = DatePicker;
  const store = useStore();
  const [form] = Form.useForm();
  const [showUserFormModal, setShowUserFormModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const[state,setState] = useState({
        username : '',
        full_name : '',
        id : '',
        last_signed_in : ''
  })

    const formItemLayout = {
        labelCol: {span: 4 },
        wrapperCol: {span: 20},
    };

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
      await store.authentication.userData();
      // store.authentication.data
      setState({
          username : store.authentication.data.username,
          full_name : store.authentication.data.full_name,
          id : store.authentication.data.id,
          last_signed_in : store.authentication.data.last_signed_in
      })
  }




  async function onSubmit(values) {
        console.log(values, "value->")

        const bodyData = {
            username: values.username,
            full_name: values.full_name,

        }
        if(values.password) {
            bodyData["password"]= values.password
        }

        try {
            await store.authentication.edit(bodyData)

            message.success("Edit User Successfully")
        } catch (e) {
            message.error("Cannot Update User Data")
        }

  }

  return (
    <div>
      <Breadcrumb style={{marginBottom: 20}}>
        <Breadcrumb.Item>
            <Link to={'/app/dashboard'}>Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span style={{color:"#ed1f24"}}>Users</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      {/* <Spin spinning={store.user.isLoading}> */}
      <Card bordered={false} className={"shadow"} bodyStyle={{ padding: 0 }}>
      {/* {modalFilter()} */}
      <PageHeader
          className={"card-page-header"}
          title="Update Profile"

        />
          {!store.authentication.isLoading && store.authentication.data ? (
        <Card>
        <Form
            {...formItemLayout}
            form={form}
            layout="horizontal"
            name="update_user"
            initialValues={store.authentication.data}
            onFinish={onSubmit}

        >
            <Form.Item
                name='id' hidden={true}
            >
                <Input style={{ width: 300 }}/>
            </Form.Item>
            <Form.Item
                name='full_name'
                label="Name"
                rules={[
                    {
                        required: true,
                        message: 'Please input name!',
                    },
                ]}
            >
                <Input style={{ width: 300 }}/>
            </Form.Item>

            <Form.Item
                name="username"
                label="Username"
                rules={[
                    {
                        required: true,
                        message: 'Please input Username!',
                    },
                ]}
            >
                <Input style={{ width: 300 }}/>
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[
                    {
                        required: state?.id ? false :true,
                        message: 'Please input password!',
                    },
                ]}
            >
                <Input.Password
                    style={{ width: 300 }}
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item
                name="last_signed_in"
                label="Last Signed In"
            >
                <Input style={{ width: 300 }} disabled={true}/>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    offset: 4,
                    span: 20,
                }}>
                <Button type="primary" htmlType="submit">
                    Simpan
                </Button>
            </Form.Item>
        </Form>
        </Card>
          ) : (
              <div
                  style={{
                      minHeight: "calc(100vh - 450px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                  }}
              >
                  <Empty />
              </div>
          )}
      </Card>

    {/* </Spin> */}
    </div>
  );
});
