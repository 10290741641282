import React, {useState, useEffect} from "react";
import {Link, withRouter, useParams} from 'react-router-dom';
import {Button, Card, Dropdown, Menu, PageHeader, Breadcrumb} from "antd";
import {FilterOutlined} from "@ant-design/icons";
import {LINKS} from "../routes";

export const BreadcrumbComponent = withRouter(props => {
    const breadcrumbNameMap = Object.assign({}, ...Object.entries(LINKS).map(([a,b]) => ({ [b]: a })));
    const {location} = props;
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        // console.log(props.params.id, breadcrumbNameMap, url, props, "breadcrumbNameMap");
        return (
            <Breadcrumb.Item key={url}>
                <Link style={{textTransform: 'capitalize'}} to={url}>{breadcrumbNameMap[url] ? breadcrumbNameMap[url]?.toLowerCase() : props?.params?.id?.toLowerCase()}</Link>
            </Breadcrumb.Item>
        );
    });

    // const breadcrumbItems = [
    //     <Breadcrumb.Item key="home">
    //         <Link to={LINKS.DASHBOARD}>Home</Link>
    //     </Breadcrumb.Item>,
    // ].concat(extraBreadcrumbItems);

    const breadcrumbItems = [].concat(extraBreadcrumbItems);

    const [lastRoute, setLastRoute] = useState([]);
    const [lastRouteRaw, setLastRouteRaw] = useState(null);
    const [lastRouteCount, setLastRouteCount] = useState(0);

    useEffect(() => {
        let newArray = [];
        if (props.data.length > 0) {
            setLastRouteCount(lastRoute.length);
        }
    });

    return <Breadcrumb style={props.style}>{breadcrumbItems}</Breadcrumb>
});
