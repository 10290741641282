import * as React from 'react';
import {Alert, Button, Modal, Space} from 'antd';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';

export const ClearCache = () => {
    const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
    function modalClearCache() {
        Modal.info({
            title: 'Update Version',
            content: (
                <div>
                    <p>There is latest version available</p>
                </div>
            ),


        });
    }
    return (
        <div>
            <Modal
                title="Update App Version"
                visible={!isLatestVersion? true: false}
                footer= {[
                <Button key="submit" type="primary"  onClick={e => {
                    e.preventDefault();
                    emptyCacheStorage();
                }}>
                    Update Version
                </Button>
            ]}>
                <p>There is Latest Version Available, click button to update!</p>
            </Modal>
        </div>

    );
};
