import {Form, Input, Modal, Select, Row, Col, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../utils/useStores";
import * as _ from "lodash";

export const WilayahForm = observer(({visible, onSubmit, onCancel, confirmLoading, initialData}) => {
	const [form] = Form.useForm();
	const [showPartnerSelection, setShowPartnerSelection] = useState(false);
	const store = useStore();

	useEffect(() => {
		init();
	}, []);

	async function init() {
	}

	function setFieldsValue(){
		if(initialData.id){
		}

	}

	function onChange(checked) {
		console.log(`switch to ${checked}`);
	  }

	return <Modal
		maskClosable={false}
		visible={visible}
		width={'448px'}
		closable={false}
		bodyStyle={{background: '#f7fafc'}}
		title="Wilayah IKAI"
		okText="Save"
		cancelText="Cancel"
		onCancel={() => {
			form.setFieldsValue({});
			onCancel();
		}}
		confirmLoading={confirmLoading}
		destroyOnClose={true}
		onOk={() => {
			form
				.validateFields()
				.then(values => {
					form.resetFields();
					onSubmit(values);
					form.setFieldsValue({});
				})
				.catch(info => {
					console.log('Validate Failed:', info);
				});
		}}
	>

		<Form
			form={form}
			layout="vertical"
			className={'custom-form'}
			name="form_in_modal"
			initialValues={initialData}

		>
			<Form.Item name='id' style={{display:'none'}}>
				<Input value={initialData.id}/>
			</Form.Item>
			<Form.Item
				name='name'
				label="Name"
				rules={[
					{
						required: true,
						message: 'Masukan nama wilayah!',
					},
				]}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				name="code"
				label="Attribute"
			>
				<Input/>
			</Form.Item>
		</Form>
	</Modal>
});
