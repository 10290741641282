import {action, computed, observable} from 'mobx';
import {http} from "../utils/http";
import qs from "querystring";

export class Authentication {

  @observable data = [];

  baseUrl = "/api/authentication";
    @observable data;
    @observable isLoading = true;

  constructor(context) {
    this.context = context;
  }
  @action
  userData() {
      this.isLoading = true;
      return http
        .get(this.baseUrl+  '/profile')
        .then((res) => {
          console.log({ res }, "UserStore -> ");
          this.isLoading = false;
          this.data = res.body.data;
          return res;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log("Error!", err);
          throw err;
        });
  }

    @action
    edit(data) {
        this.isLoading = true;
        console.log('EDIT ', data)
        return http
            .put(this.baseUrl + '/profile', data)
            .then((res) => {
                console.log({ res }, "UserStore -> Edit");
                this.isLoading = false;
                return res;
            })
            .catch((err) => {
                this.isLoading = false;
                console.log("Error!", err);
                throw err;
            });
    }

  @action
  login = async ({email, password}) => {
    const loginResponse = await http.post('/api/authentication/login').send({
      email,
      password,
    });

    if(loginResponse.body.message === 'success') {
      this.context.setToken(loginResponse.body.token);
      this.context.setRole(loginResponse.body.role);
    }
  }

  @action
  logout() {
    this.context.setToken();
    this.context.setRole();
    this.context.clearState();
  }
}
