import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Login } from "../pages/Login/Login";
import { App } from "../pages/App/App";
import { AnimatedSwitch } from "react-router-transition";
import PublicRoute from "../component/PublicRoute";
import PrivateRoute from "../component/PrivateRoute";

export const LINKS = {
  LOGIN: "/login",
  REGISTER: "/register",
  HOME: "/app",
  DASHBOARD: "/app/dashboard",
  TASKS: "/app/tasks",
  PARTNER: "/app/partners",
  SERVICE: "/app/services",
  REPORT: "/app/reports",
  KPI: "/app/Kpi",
  SETTING: "/app/settings",
  PROFILE: "/app/profile",
  BALANCE: "/app/balances",
  ABSEN: "/absen",
  // KPI: "/app/reports/Kpi",
  LIST: "/app/reports/list",
  USERS: "/app/reports/list/Users",
  CORPORATE: "/app/Kpi/Corporate",
  "KEY METRICS": "/app/Key_Metrics",
 "KEY-METRICS": [{"EXECUTIVE SUMMARY": "/app/Key_Metrics/executive-summary"},
     {"TRAFFIC CLASSIFICATION": "/app/Key_Metrics/traffic-classification",}],
  'EXECUTIVE SUMMARY': "/app/Key_Metrics/executive-summary",
  "TRAFFIC CLASSIFICATION": "/app/Key_Metrics/traffic-classification",
  "BALANCE SHEET": "/app/Key_Metrics/balance-sheet",
  "DAILY REVENUE": "/app/Key_Metrics/daily-revenue",
  DIVISIONS: "/app/Kpi/Divisions",
  CALENDAR: "/app/calendar",
  UPLOAD: "/app/upload-data",
  ROLE: "/app/settings/role",
  "DIVISION & DEPARTEMENT": "/app/settings/division-departement",
  'KEY METRICS DATA': "/app/Key_Metrics/input-data-key-metric",
  'OPERATIONAL DATA UPLOAD': "/app/operational-data/input-data-operational",
  'ENTRY CORPORATE': "/app/Kpi/entry-kpi-corporate",
  'ENTRY DIVISI': "/app/Kpi/entry-kpi-divisi",
  'ENTRY DIVISI GOLONGAN 1-3': "/app/Kpi/entry-kpi-divisi/entry-kpi-divisi-gol-I-III",
  'ENTRY DIVISI GOLONGAN 4-5': "/app/Kpi/entry-kpi-divisi/entry-kpi-divisi-gol-IV-V",
  'DETAIL KPI DIVISI': "/app/report/kpi/divisi/",
  'DETAIL KPI DEPARTMENT': "/app/report/kpi/department/:id",

  // ['DETAIL DEPARTMENT']: "/app/Kpi/Departments/:id",
  'ENTRY DEPARTMENT': "/app/Kpi/entry-kpi-department",
  'ENTRY DEPARTMENT GOLONGAN 1-3': "/app/Kpi/entry-kpi-department/entry-kpi-department-gol-I-III",
  'ENTRY DEPARTMENT GOLONGAN 4-5': "/app/Kpi/entry-kpi-department/entry-kpi-department-gol-IV-V",
  'ENTRY COMMISSIONS': "/app/Kpi/entry-kpi-commissions",
  'ENTRY STAFF': "/app/Kpi/entry-kpi-staff",
  'ENTRY STAFF GOLONGAN 1-3': "/app/Kpi/entry-kpi-staff/entry-kpi-staff-gol-I-III",
  'ENTRY STAFF GOLONGAN 4-5': "/app/Kpi/entry-kpi-staff/entry-kpi-staff-gol-IV-V",
  'STAFF ENTRY KPI': "/app/Kpi/staff-entry-kpi",


  COMMISIONS: "/app/Kpi/Commissions",

  'DETAIL DEPARTMENT': '/app/Kpi/Departments',
  'DETAIL DIVISI': '/app/Kpi/Divisions',
  'DETAIL STAFF': '/app/Kpi/Staffs',
  'DAILY TRAFFIC VOLUME': "/app/Key_Metrics/daily-traffic-volume",
  'AUDIT LOG': "/app/settings/audit-log",
  FORMULA : "/app/settings/formula",
  ACCIDENT: "/app/Key_Metrics/accident",
  "FULL ROUTE EQUIVALENTE": "/app/Key_Metrics/full-route-equivalente",
  'AVERAGE VEHICLE KM TRAVELLED': "/app/Key_Metrics/average-vechile-km",
  CASHFLOW: "/app/Key_Metrics/cashflow",
  STAFF: '/app/Kpi/kpi-staff',
  DAILY_REVENUE: '/app/reports/key_metric/daily_revenue',
  'FINANCIAL SUMMARY': '/app/Key_Metrics/financial-summary',
  ORGANIZATIONS: '/app/reports/list/Organizations',
  DEPARTMENTS: '/app/Kpi/Departments',
  "OPERATIONAL DATA": '/app/operational-data',

  TRANSACTION_OPERATION: "/app/reports/transaction_operation",
  TRANSACTION_OPERATION_DETAIL: "/app/reports/transaction_operation/:id",
  INVOICES: "/app/reports/invoices",
  BILLS: "/app/reports/bills",

  BALANCE_TRANSACTION: "/app/balances/transactions",
  BALANCE_ACCOUNT: "/app/balances/accounts",
  BALANCE_INVOICES: "/app/balances/invoices",
  SETTING_USER: "/app/settings/users",
  VISION_MISION: "/app/settings/vision-mission",
  ENTRY_KPI: "/app/settings/entry-kpi",

  SERVICE_2W: "/app/services/2w",
  SERVICE_2W_DASHBOARD: "/app/services/2w/dashboard",
  SERVICE_2W_PAYMENT: "/app/services/2w/payment",
  SERVICE_2W_TRANSACTION: "/app/services/2w/transactions",
  SERVICE_2W_RECONCILE: "/app/services/2w/reconcile",
  SERVICE_2W_HISTORY: "/app/services/2w/history",
  SERVICE_2W_FRAUD: "/app/services/2w/fraud",

  SERVICE_SUBSCRIPTIONS: "/app/services/subscriptions",
  SERVICE_COMMISSIONS: "/app/services/commissions",
  SERVICE_DEPOSITS: "/app/services/deposits",
  PARTNER_2W: "/app/partners/2w",
  PARTNER_2W_DASHBOARD: "/app/partners/2w/dashboard",
  PARTNER_2W_TRANSACTION: "/app/partners/2w/transactions",
  PARTNER_2W_RECONCILE: "/app/partners/2w/reconcile",
  PARTNER_2W_PAYMENT: "/app/partners/2w/payment",
  PARTNER_2W_HISTORY: "/app/partners/2w/history",
  PARTNER_2W_FRAUD: "/app/partners/2w/fraud",
};

export const MainRoutes = (props) => {
  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    >
      <Route path="/" exact>
        <Redirect to={"/login"} />
      </Route>
      <PublicRoute restricted={true} component={Login} path="/login" exact />
      {/*<PublicRoute restricted={true} component={Login} path="/register" exact />*/}
      <PrivateRoute component={App} path="/app" />
    </AnimatedSwitch>
  );
};
